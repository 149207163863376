@import "../../utils/commons.scss";

.container {
  width: 100%;
  margin-top: 1rem;
  > div {
    h3 {
      color: white;
    }
    margin: 1rem 0;
  }
  section {
    height: 70vh;
    width: 100%;
    border: 2px dotted grey;
    border-radius: 5px;
    > div {
      width: 100%;
      height: 100%;
      @extend .flexRow;
      justify-content: center;
    }
  }
}
