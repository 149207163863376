@import "../../utils/commons.scss";

.container {
  width: 100%;
  padding: 0rem 1rem;
  .tableContainer {
    // width: var(--fixed-width);

    // border-radius: 10px !important;
    // overflow: hidden;
    min-height: 500px;
  }
  .header {
    @extend .flexRow;
    margin-top: 2rem;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 1rem;
    > div {
      @extend .flexRow;
      align-items: flex-end;
      justify-content: space-between;
      button {
        margin-left: 1rem;
      }
    }
  }
}
.editModal {
  width: 100%;
  height: 100vh;
  @extend .flexRow;
  justify-content: center;

  form {
    background: var(--clr-bg-dark);
    padding: 2rem;
    border-radius: 10px;
    h2 {
      color: white;
    }
    button {
      margin-top: 2rem;
    }
  }
}
