@import "../../utils/commons.scss";

.container {
  width: 100%;
  padding: 0rem 1rem;
  .tableContainer {
    // width: var(--fixed-width);

    // border-radius: 10px !important;
    // overflow: hidden;
    // height: 500px;
    margin-top: 1rem;
    .flexRow button {
      margin: 1rem 0;
      // margin-left: auto;
    }
  }
  button {
    img {
      width: 30px;
    }
  }
}
.tabs {
  @extend .flexRow;
  div > div > span {
    background-color: var(--clr-primary) !important;
  }
}
.dialog {
  > div:nth-of-type(3) > div {
    background: var(--clr-bg-dark) !important;
  }
  header {
    background: var(--clr-primary);
  }
  .tablesContainer {
    width: 90%;
    margin: 2rem auto;

    .tabs {
      margin: 1rem 0;
      img {
        width: 30px;
      }
    }
  }
}
.newColDrawer {
  width: 100%;
  height: 100%;
  padding: 2rem;
  background: var(--clr-bg-dark);
  h3 {
    color: white;
  }
  input {
    margin: 0.5rem 0;
  }
  button {
    margin: 2rem 0;
    width: 100%;
    span {
      text-align: center;
      width: 100%;
    }
  }
}
.tempContainer {
  @extend .flexRow;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: var(--clr-bg-dark);
  * {
    color: white;
  }
}
