@import "../../utils/commons.scss";

.container {
  background: var(--clr-bg-dark);
  min-height: 100vh;
  width: 100%;

  .buttons {
    @extend .flexRow;
    p {
      cursor: pointer;
      -webkit-user-select: none;
      user-select: none;
      color: white;
      &:hover {
        text-shadow: 0 0 1px white;
      }
    }
  }
  form {
    @extend .flexCol;
    > div {
      margin: 1rem 0;
      width: 400px;
    }
  }
  .loading {
    margin-top: 0.5rem;
    width: 400px;
    background-color: transparent;
    > span {
      background-color: var(--clr-primary);
    }
  }
  .error {
    background: rgb(100, 1, 1);
    padding: 1rem;
    border-radius: 5px;
    text-align: center;
    color: white;
    margin: 1rem 0;
  }
}
.backToLogin {
  display: flex;
  justify-content: flex-start;
  width: 400px;
  margin: 1rem auto;
  color: white;
  cursor: pointer;
  p {
    margin-left: 1rem;
  }
}
